import React from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-activity';
import 'react-activity/dist/library.css';

const SIZE_DEFAULT = 45;
const SPEED_DEFAULT = 0.7;
const ANIMATION_DEFAULT = true;
const COLOR_DEFAULT = '#941c22FF';

const baseClass = 'loader';

const Loader = ({ className, ...restProps }) => {
    return (
        <div className={`${baseClass}__wrapper ${className}`}>
            <Spinner {...restProps} />
        </div>
    );
};
/**
 * @props string url The url of the button. Empty for a goBack.
 * @props string size The size of the button. Big or medium, medium by default.
 * @props string value The value of the button.
 **/
Loader.propTypes = {
    size: PropTypes.number,
    speed: PropTypes.number,
    animating: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string
};

Loader.defaultProps = {
    size: SIZE_DEFAULT,
    speed: SPEED_DEFAULT,
    animating: ANIMATION_DEFAULT,
    color: COLOR_DEFAULT
};

export default Loader;
