export const PATH_ROOT = '/';
export const PATH_LANDING = '/landing';
export const PATH_HOME = '/home';
export const PATH_LOGIN = '/login';
export const PATH_LOGOUT = '/logout';
export const PATH_EXIT = '/exit';
export const PATH_SUBSCRIBE = '/inscription';
export const PATH_SEARCH = '/search';
export const PATH_DETAIL = '/detail';
export const PATH_PLAY = '/play';
export const PATH_PROFILE = '/profile';

export const PATH_PARAMS_FILM_ID = '/:FilmID';
// export const PATH_PARAMS_ACTION_QUIT = '/:action(quit)?';

export const customHistory = [];

export function isReturnEvent(evt) {
    if (
        evt.keyCode === 27 ||
        evt.keyCode === 10009 /* Samsung Remote */ ||
        evt.keyCode === 461 /* LG Magic Remode */
    ) {
        evt.preventDefault();
        evt.stopImmediatePropagation();
        return true;
    }
    return false;
}

export function goTo(history, url, replace = false) {
    if (replace && 'replace' in history) {
        customHistory[customHistory.length - 1] = url;
        history.replace(url);
    } else {
        customHistory.push(url);
        history.push(url);
    }
}

export function goBack(history) {
    customHistory.pop();
    history.goBack();
}
