import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Wrapper from 'components/Wrapper';
import InputCode from './InputCodeV2';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    STYLE_OUTLINE as BTN_OUTLINE,
    STYLE_EMPTY as BTN_EMPTY
} from 'components/Button';
import Title, { LEVEL_1 } from 'components/Title';
import Text, { COLOR_ERROR } from 'components/Text';
import { goTo } from 'utils/NavigationUtils';
import useAuth from 'hooks/useAuth';

const SubscriptionCode = ({ navigation }) => {
    const [code, setCode] = useState('');
    const [error, setError] = useState('');

    const history = useHistory();
    const { loginByCode } = useAuth();
    const btnLoginRef = createRef();
    const btnRedirectRef = createRef();

    const login = () => {
        loginByCode(code).then(res => {
            if (!res) setErrorMessage(res);
            // else goTo('/')
        });
    };

    const setErrorMessage = message => {
        switch (message) {
            case 'Wrong code':
                setError('Code faux');
                break;
            case 'Code expired':
                setError('Code expiré');
                break;
            default:
                setError('Une erreur est survenue. Veuillez réessayer plus tard');
        }
    };

    const onCodeFull = () => {
        if (!btnLoginRef?.current?.focusableId) return;
        navigation.current.forceFocus(btnLoginRef.current.focusableId);
    };

    const goToLogin = () => goTo(history, '/login');

    useEffect(() => console.log('code', code), [code]);

    return (
        <Wrapper className="inscription__form" position={Wrapper.POSITION_CENTERED}>
            <Title className="inscription__form__title" level={LEVEL_1}>
                Entrez le code à 8 chiffres que vous avez reçu par e-mail
            </Title>
            <InputCode
                className="inscription__form__input"
                codeLength={8}
                codeValue={code}
                navigation={navigation}
                setCodeValue={setCode}
                onLastDigitEntered={onCodeFull}
            />
            {error.length <= 0 ? (
                <Button
                    className="inscription__form__button"
                    history={history}
                    navigation={navigation}
                    ref={btnLoginRef}
                    size={BUTTON_GIANT}
                    style={BTN_OUTLINE}
                    url={login}
                >
                    <Text>Continuer</Text>
                </Button>
            ) : (
                <Text className="inscription__form__error" color={COLOR_ERROR}>
                    {error}
                </Text>
            )}
            <Button
                className="inscription__form__button"
                history={history}
                navigation={navigation}
                ref={btnRedirectRef}
                size={BUTTON_GIANT}
                style={BTN_EMPTY}
                url={goToLogin}
            >
                <Text>Me connecter avec mes identifiants</Text>
            </Button>
        </Wrapper>
    );
};

SubscriptionCode.propTypes = {
    action: PropTypes.func,
    navigation: PropTypes.object
};

SubscriptionCode.defaultProps = {};

export default SubscriptionCode;
