import React, { Component } from 'react';
import { Focusable } from 'components/Navigation';
import SvgIcon from './SvgIcon';
import Text, { COLOR_SECONDARY } from './Text';

class MenuItem extends Component {
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            selected: false
        };

        this._onEnterDown = this._onEnterDown.bind(this);
        this.onMouseEnterHandler = this.onMouseEnterHandler.bind(this);
        this.timer = null;

        this.item = React.createRef();
    }

    _onEnterDown(obj, navig) {
        this.props.goTo();
    }

    onMouseEnterHandler(event) {
        if (!this.props.navigation) return;

        if (typeof this.props.onMouseEnterItem === 'function') this.props.onMouseEnterItem(event);

        this.props.navigation.current.forceFocus(this.item.focusableId);
    }

    onFocusHandler(event) {
        if (typeof this.props.onFocusItem === 'function') this.props.onFocusItem(event);
        this.setState({ active: true });
    }

    render() {
        return (
            <li>
                <Focusable
                    className={
                        'menuitem' +
                        (this.state.active ? ' menuitem--active' : '') +
                        (this.props.selected ? ' menuitem--selected' : '') +
                        (this.props.separator ? ' menuitem--separator' : '')
                    }
                    ref={el => (this.item = el)}
                    onMouseEnter={this.onMouseEnterHandler}
                    onFocus={this.onFocusHandler.bind(this)}
                    onBlur={() => this.setState({ active: false })}
                    onClick={(e, n) => this._onEnterDown(e, n)}
                    onEnterDown={this._onEnterDown}
                    onSelect={() => this.setState({ selected: true })}
                >
                    <SvgIcon icon={this.props.icon} />
                    <Text color={COLOR_SECONDARY}>{this.props.children}</Text>
                </Focusable>
            </li>
        );
    }
}

export default MenuItem;
