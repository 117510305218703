import React, { createContext, useEffect, useState } from 'react';

const appDomainRegex = /[.](?:zone300)[.]((tv)|(com))$/;

const SystemContext = createContext({
	system: null,
	version: null,
	deviceId: null
});

const SystemProvider = ({ children }) => {
	const device = new URLSearchParams(window.location.search).get('device');
	const version = '210514';
	const [system, setSystem] = useState(device);

	useEffect(() => {
		if (!device) {
			const subDomain = window.location.hostname.replace(appDomainRegex, '');
			setSystem(subDomain);
		} 
	}, [device]);

	return <SystemContext.Provider 
		value={{ 
			system, 
			version, 
			deviceId: 'app_tv_' + system + '_v' + version 
		}}>
		{children}
	</SystemContext.Provider>;
};

export {
	SystemContext as default,
	SystemProvider
};
