import React, { Component, createRef } from 'react';
import { withLastLocation } from 'react-router-last-location';

import { VerticalList } from 'components/Navigation';
import Wrapper from 'components/Wrapper';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    STYLE_OUTLINE as BTN_OUTLINE,
    BLOCK_THIRD as BTN_THIRD
} from 'components/Button';
import { goTo, isReturnEvent, PATH_EXIT } from 'utils/NavigationUtils';
import Text from 'components/Text';
import Image from 'components/Image';
import Title, { LEVEL_1 } from 'components/Title';

class Landing extends Component {
    navigation = null;

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            image: null
        };
        this.keyDownListener = this.keyDownListener.bind(this);

        this.registerBtn = createRef();
        this.loginBtn = createRef();
    }

    /**
     * Open sidebar on specified item focus
     * @param evnt
     */
    keyDownListener(evnt) {
        if (isReturnEvent(evnt)) {
            goTo(this.props.history, PATH_EXIT);
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyDownListener);
        this.setState({ isLoaded: true });
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    render() {
        return (
            <div className="landing">
                <Image
                    className="landing__background"
                    url={require('assets/img/background.jpg')}
                    size={Image.SIZE_FULLSCREEN}
                />
                <Wrapper overlay={Wrapper.OVERLAY_GRADIENT_LEFT} className="landing__overlay">
                    <div>
                        <div className="landing__logo">
                            <img
                                src={require('../assets/img/Logo-Cheval_BLANC.png')}
                                alt="Logo Cheval TV"
                            />
                        </div>
                        <Title className="landing__title" level={LEVEL_1}>
                            L'unique chaîne dédiée aux chevaux et poneys
                        </Title>
                    </div>
                    <VerticalList>
                        <div className="landing__register">
                            <Text className="landing__register__text">
                                Commencez à regarder Cheval TV pour 4,99€/mois
                            </Text>
                            <Button
                                ref={this.registerBtn}
                                block={BTN_THIRD}
                                history={this.props.history}
                                navigation={this.props.navigation}
                                size={BUTTON_GIANT}
                                style={BTN_OUTLINE}
                                url="/inscription/"
                            >
                                <Text>S'inscrire</Text>
                            </Button>
                        </div>
                        <div className="landing__login">
                            {/* <Text className="landing__login__text">Vous avez déja un compte ?</Text> */}
                            <Button
                                ref={this.loginBtn}
                                block={BTN_THIRD}
                                navigation={this.props.navigation}
                                size={BUTTON_GIANT}
                                url="/login/"
                                history={this.props.history}
                                style={BTN_OUTLINE}
                            >
                                <Text>Se connecter avec mes identifiants</Text>
                            </Button>
                        </div>
                    </VerticalList>
                </Wrapper>
            </div>
        );
    }
}

export default withLastLocation(Landing);
