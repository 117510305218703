import React, { createContext, useEffect, useReducer } from 'react';
import ApiService from 'services/ApiService';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    userSubscribed: false,
    user: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload;
            // const userValidated = user?.has_password || false;
            const userSubscribed =
                (user?.has_password &&
                    user?.TypeAbo &&
                    user?.TypeAbo != '0' &&
                    !user?.status?.discarded) ||
                false;

            return {
                ...state,
                isAuthenticated,
                isInitialized: true,
                userSubscribed,
                user
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        }
        case 'REFRESH_USER': {
            const { user } = action.payload;

            return {
                ...state,
                user
            };
        }
        default: {
            return { ...state };
        }
    }
};

const ITEM_NO_CLIENT = 'NO_CLIENT';

const setUserSession = user => {
    localStorage.setItem(ITEM_NO_CLIENT, user.NoCli);
    localStorage.setItem('LOGIN_TOKEN', JSON.stringify(user));
};

const clearSession = () => {
    localStorage.removeItem(ITEM_NO_CLIENT);
    localStorage.removeItem('LOGIN_TOKEN');
};

const AuthContext = createContext({
    ...initialState,
    getHlsSrc: () => Promise.resolve(),
    login: () => Promise.resolve(),
    loginByCode: () => Promise.resolve(),
    logout: () => {}
});

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        (async () => {
            try {
                let user = JSON.parse(localStorage.getItem('LOGIN_TOKEN'));

                let isAuthenticated = false;
                let userSubscribed = false;
                if (user) {
                    // Platform is not accessible if you're not subscribed to the service, so authenticated = subscribed
                    const { status, user: newUserData } = await checkSubscription(user.NoCli);
                    user = { ...newUserData, status };
                    isAuthenticated = true;
                    userSubscribed = !status.discarded;
                }
                dispatch({
                    type: 'INIT',
                    payload: { isAuthenticated, user, userSubscribed }
                });
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        userSubscribed: false,
                        user: null
                    }
                });
            }
        })();
    }, []);

    const login = async (email, password) => {
        const params = new URLSearchParams();
        params.append('email', email);
        params.append('password', password);

        const res = await ApiService.post('user/login_app.php', params);

        if (res.data.ret) {
            const {
                data: { user, status }
            } = res.data;

            setUserSession({ ...user, status });
            dispatch({
                type: 'LOGIN',
                payload: {
                    user: {
                        ...user,
                        status
                    }
                }
            });
        }

        return res;
    };

    const loginByCode = async code => {
        const data = new URLSearchParams();
        data.append('code', code);

        const res = await ApiService.post('user/authByCode.php', data);
        if (!res.data.ret) return false;

        const user = await getUser(res.data.data, false);
        setUserSession(user);
        dispatch({
            type: 'LOGIN',
            payload: {
                user
            }
        });
    };

    const logout = () => {
        clearSession();
        dispatch({ type: 'LOGOUT' });
    };

    const checkSubscription = noCli => {
        return ApiService.get(`user/getUserInfo.php?NoCli=${noCli}`)
            .then(({ data }) => {
                // TODO change condition
                if ((!data.ret && data.data === 'authentication') || data.data.status.discarded) {
                    throw new Error();
                }
                return data.data;
            })
            .catch(() => {
                return false;
            });
    };

    const getUser = async (noCli, updateState = true) => {
        const { user, status } = await ApiService.get(`user/getUserInfo.php?NoCli=${noCli}`).then(
            res => res.data.data
        );

        const newUser = {
            ...user,
            status
        };

        if (updateState) {
            dispatch({
                type: 'REFRESH_USER',
                payload: {
                    user: newUser
                }
            });
        } else {
            return newUser;
        }
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                loginByCode,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext as default, AuthProvider };
