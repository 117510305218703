import React, { useState, forwardRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Focusable } from 'components/Navigation';

import StringUtils from 'utils/StringUtils';
import { goBack, goTo } from 'utils/NavigationUtils';

export const SIZE_MEDIUM = 'medium';
export const SIZE_BIG = 'big';
export const SIZE_GIANT = 'giant';
export const BLOCK_DEFAULT = 'block-default';
export const BLOCK_FULL = 'block-full';
export const BLOCK_HALF = 'block-half';
export const BLOCK_THIRD = 'block-third';
export const STYLE_PLAIN = 'style-plain';
export const STYLE_OUTLINE = 'outline';
export const STYLE_EMPTY = 'empty';
export const SIZE_ROUND = 'round';

const baseClass = 'button';

const getCssClasses = (className, style, size, block, isFocus, isDisabled) => {
    let classes = (className ? `${className} ` : '') + baseClass;

    switch (size) {
        case SIZE_GIANT:
            classes += ` ${baseClass}--${SIZE_GIANT}`;
            break;
        case SIZE_BIG:
            classes += ` ${baseClass}--${SIZE_BIG}`;
            break;
        case SIZE_ROUND:
            classes += ` ${baseClass}--${SIZE_ROUND}`;
            break;
        case SIZE_MEDIUM:
        default:
            classes += ` ${baseClass}--${SIZE_MEDIUM}`;
    }

    switch (block) {
        case BLOCK_FULL:
            classes += ` ${baseClass}--${BLOCK_FULL}`;
            break;
        case BLOCK_HALF:
            classes += ` ${baseClass}--${BLOCK_HALF}`;
            break;
        case BLOCK_THIRD:
            classes += ` ${baseClass}--${BLOCK_THIRD}`;
            break;
        default:
            break;
    }

    switch (style) {
        case STYLE_OUTLINE:
            classes += ` ${baseClass}--${STYLE_OUTLINE}`;
            break;
        case STYLE_EMPTY:
            classes += ` ${baseClass}--${STYLE_EMPTY}`;
            break;
        case STYLE_PLAIN:
        default:
            classes += ` ${baseClass}--${STYLE_PLAIN}`;
    }

    classes += isFocus ? ` ${baseClass}--focus` : '';

    classes += isDisabled ? ` ${baseClass}--disabled` : '';

    return classes;
};

const Button = (
    {
        block,
        children,
        disabled,
        className,
        focusOnLoad,
        navigation,
        parentOnFocusHandler,
        replaceUrl,
        size,
        style,
        url
    },
    buttonRef
) => {
    const history = useHistory();
    const [isFocus, setIsFocus] = useState(false);

    const ButtonAction = (url, replaceUrl = false) => {
        if (typeof url === 'function') {
            url();
        } else {
            if (StringUtils.isEmpty(url)) {
                goBack(history);
            } else {
                goTo(history, url, replaceUrl);
            }
        }
    };

    const onMouseEnterHandler = () => {
        if (!buttonRef?.current?.focusableId) return;

        if (typeof navigation?.current?.forceFocus === 'function') {
            navigation.current.forceFocus(buttonRef.current.focusableId);
            setIsFocus(true);
        }
    };

    const onFocusHandler = event => {
        if (typeof parentOnFocusHandler === 'function') {
            parentOnFocusHandler(event);
        }
        setIsFocus(true);
    };

    const onBlurHandler = () => {
        setIsFocus(false);
    };

    const onEnterDownHandler = () => {
        ButtonAction(url, replaceUrl);
    };

    const onClickHandler = () => {
        ButtonAction(url);
    };

    return (
        <Focusable
            ref={buttonRef}
            className={getCssClasses(className, style, size, block, isFocus, disabled)}
            onMouseEnter={onMouseEnterHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onEnterDown={onEnterDownHandler}
            onClick={onClickHandler}
            navDefault={focusOnLoad}
        >
            {children}
        </Focusable>
    );
};

export default forwardRef(Button);
