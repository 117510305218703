import React, { createRef } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import useAuth from 'hooks/useAuth';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import Profile from 'pages/Profile';
import Subscription from 'components/Subscription';
import SplashScreen from 'components/SplashScreen';
import {
    // customHistory,
    // goTo,
    // PATH_ROOT,
    PATH_HOME,
    PATH_LOGIN,
    PATH_EXIT,
    PATH_SUBSCRIBE,
    PATH_PROFILE,
    PATH_LANDING
} from 'utils/NavigationUtils';
import { SystemContext } from 'contexts';
import LivePlayer from 'components/Player/LivePlayer';
import Navigation from 'components/Navigation';
// import Discover from 'components/Discover';

const NavigationLayout = ({ content: Content, ...restProps }) => {
    const navigation = createRef();

    return (
        <Navigation ref={navigation}>
            <Content {...restProps} navigation={navigation} />
        </Navigation>
    );
};

const Router = () => {
    const { isAuthenticated, isInitialized } = useAuth();
    // const navigation = createRef();

    return !isInitialized ? (
        <SplashScreen parent={this} />
    ) : (
        // <Navigation ref={navigation}>
        <BrowserRouter>
            <LastLocationProvider>
                <SystemContext.Consumer>
                    {systemValues => (
                        <Switch>
                            {isAuthenticated ? (
                                <>
                                    <Route
                                        exact
                                        path={PATH_HOME}
                                        render={props => (
                                            <NavigationLayout
                                                content={LivePlayer}
                                                {...props}
                                                hostname={systemValues.system}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={PATH_PROFILE}
                                        render={props => (
                                            <NavigationLayout
                                                content={Profile}
                                                {...props}
                                                hostname={systemValues.system}
                                            />
                                        )}
                                    />
                                    <Redirect path="*" to="/home" />
                                </>
                            ) : (
                                <>
                                    {/* NOT AUTHENTICATED ROUTES */}
                                    <Route
                                        exact
                                        path={PATH_LANDING}
                                        render={props => (
                                            <NavigationLayout
                                                content={Landing}
                                                {...props}
                                                hostname={systemValues.system}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={PATH_SUBSCRIBE}
                                        render={props => (
                                            <NavigationLayout
                                                // content={Discover}
                                                content={Subscription}
                                                {...props}
                                                hostname={systemValues.system}
                                            />
                                        )}
                                    />
                                    <Route
                                        exact
                                        path={PATH_LOGIN}
                                        render={props => (
                                            <NavigationLayout
                                                content={Login}
                                                {...props}
                                                hostname={systemValues.system}
                                            />
                                        )}
                                    />
                                    <Redirect path="*" to="/landing" />
                                </>
                            )}
                            <Route
                                path={PATH_EXIT}
                                render={props => {
                                    if (['amazon', 'android'].includes(systemValues.system))
                                        window.closeApp();
                                    window.close();
                                }}
                            />
                        </Switch>
                    )}
                </SystemContext.Consumer>
            </LastLocationProvider>
        </BrowserRouter>
        // </Navigation>
    );
};

export default Router;
