import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Wrapper from 'components/Wrapper';
import Input from 'components/InputV2';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    STYLE_OUTLINE as BTN_OUTLINE
} from 'components/Button';
import Title, { LEVEL_1 } from 'components/Title';
import Text, { COLOR_ERROR, ALIGN_CENTER, SIZE_SMALL as TEXT_SMALL } from 'components/Text';

const MESSAGE_ERROR_EMAIL = "L'adresse Email doit être renseignée";

const SubscriptionEmail = ({
    navigation,
    email,
    emailChangeHandler,
    emailError,
    isEmailValid,
    register
}) => {
    const history = useHistory();
    const btnRef = createRef();

    return (
        <>
            <Wrapper className="inscription__form" position={Wrapper.POSITION_CENTERED}>
                <Title className="inscription__form__title" level={LEVEL_1}>
                    Entrez votre e-mail pour continuer votre inscription
                </Title>
                {/* <Input
                    className="inscription__form__input"
                    type="text"
                    placeholder="E-mail"
                    name="email"
                    size={Input.SIZE_BIG}
                    navigation={navigation}
                    autoFocus={true}
                    onChange={emailChangeHandler}
                    value={email}
                    withLabel={false}
                /> */}
                <Input
                    className="inscription__form__input"
                    type="text"
                    placeholder="E-mail"
                    name="email"
                    navigation={navigation}
                    navDefault={true}
                    onChange={emailChangeHandler}
                    value={email}
                />
                <Button
                    className="inscription__form__button"
                    history={history}
                    navigation={navigation}
                    ref={btnRef}
                    size={BUTTON_GIANT}
                    style={BTN_OUTLINE}
                    url={register}
                >
                    <Text>Continuer</Text>
                </Button>

                {!isEmailValid && emailError && (
                    <Text state={COLOR_ERROR} align={ALIGN_CENTER}>
                        {MESSAGE_ERROR_EMAIL}
                    </Text>
                )}
            </Wrapper>
            {/* <Wrapper className="inscription__text">
                <Text size={TEXT_SMALL}>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam ornare bibendum
                    consequat. Maecenas eleifend ligula justo, id tempor lectus malesuada et. Ut
                    sollicitudin odio libero, ac maximus tortor faucibus at. Aliquam vitae congue
                    risus, non commodo ipsum. Fusce vehicula augue eget pharetra feugiat. In vel
                    iaculis nunc, quis auctor dui. Sed scelerisque eget libero non accumsan. Sed ut
                    orci sed ante pretium mollis. In ut scelerisque nibh. Sed mollis eleifend arcu
                    sit amet sodales.
                </Text>
            </Wrapper> */}
        </>
    );
};

SubscriptionEmail.propTypes = {
    email: PropTypes.string,
    emailChangeHandler: PropTypes.func,
    navigation: PropTypes.object,
    register: PropTypes.func
};

SubscriptionEmail.defaultProps = {};

export default SubscriptionEmail;
