import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CssClassUtils from 'utils/CssClassUtils';

/** This components is a wrapper. */
class Wrapper extends Component {
    /** The sticky bottom state. **/
    static STICKY_BOTTOM = 'sticky-bottom';

    /** The none padding state. **/
    static PADDING_NONE = 'padding-none';

    /** The none padding state. **/
    static POSITION_CENTERED = 'centered';

    /** The none padding state. **/
    static SIZE_HALF = 'size-half';

    /** The base css class name. **/
    static CSS_CLASS_BASE = 'wrapper';

    /** The css class for overlay **/
    static OVERLAY = 'overlay';

    /** The css class for overlay with gradient from left **/
    static OVERLAY_GRADIENT_LEFT = 'overlay-gl';

    /** The css class for overlay with gradient from left **/
    static OVERLAY_GRADIENT_BACK = 'overlay-gb';

    /** The sticky bottom css class name. **/
    static CSS_CLASS_STICKY_BOTTOM =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.STICKY_BOTTOM;

    /** The padding none css class name. **/
    static CSS_CLASS_PADDING_NONE =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.PADDING_NONE;

    /** The position centered css class name. **/
    static CSS_CLASS_POSITION_CENTERED =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.POSITION_CENTERED;

    /** The position centered css class name. **/
    static CSS_CLASS_SIZE_HALF =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.SIZE_HALF;

    /** The overlay css class name. **/
    static CSS_CLASS_OVERLAY =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.OVERLAY;

    /** The overlay with gradient from left css class name. **/
    static CSS_CLASS_OVERLAY_GRADIENT_LEFT =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.OVERLAY_GRADIENT_LEFT;

    /** The overlay with gradient from back css class name. **/
    static CSS_CLASS_OVERLAY_GRADIENT_BACK =
        Wrapper.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Wrapper.OVERLAY_GRADIENT_BACK;

    /** Return the css classes depending on the props. **/
    getCssClasses(className, sticky, padding, position, size, overlay) {
        let classes = className ? `${className} ${Wrapper.CSS_CLASS_BASE}` : Wrapper.CSS_CLASS_BASE;

        if (sticky === Wrapper.STICKY_BOTTOM) {
            classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_STICKY_BOTTOM;
        }

        if (padding === Wrapper.PADDING_NONE) {
            classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_PADDING_NONE;
        }

        if (position === Wrapper.POSITION_CENTERED) {
            classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_POSITION_CENTERED;
        }

        if (size === Wrapper.SIZE_HALF) {
            classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_SIZE_HALF;
        }

        switch (overlay) {
            case Wrapper.OVERLAY_GRADIENT_LEFT:
                classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
                classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY_GRADIENT_LEFT;
                break;
            case Wrapper.OVERLAY_GRADIENT_BACK:
                classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
                classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY_GRADIENT_BACK;
                break;
            case Wrapper.OVERLAY:
                classes += CssClassUtils.CLASS_SEPARATOR + Wrapper.CSS_CLASS_OVERLAY;
                break;
            default:
                break;
        }

        return classes;
    }

    /** Render the wrapper. */
    render() {
        const { className, sticky, padding, position, size, overlay } = this.props;
        return (
            <div
                className={this.getCssClasses(className, sticky, padding, position, size, overlay)}
            >
                {this.props.children}
            </div>
        );
    }
}

/**
 * @props string sticky The type of stick (bottom, top, etc).
 * @props string padding The padding style.
 * @props string position The centered position.
 * @props string size The width of the wrapper.
 **/
Wrapper.propTypes = {
    className: PropTypes.string,
    sticky: PropTypes.oneOf([Wrapper.STICKY_BOTTOM]),
    padding: PropTypes.oneOf([Wrapper.PADDING_NONE]),
    position: PropTypes.oneOf([Wrapper.POSITION_CENTERED]),
    size: PropTypes.oneOf([Wrapper.SIZE_HALF]),
    overlay: PropTypes.oneOf([
        Wrapper.OVERLAY,
        Wrapper.OVERLAY_GRADIENT_LEFT,
        Wrapper.OVERLAY_GRADIENT_BACK
    ])
};

export default Wrapper;
