import React, { Fragment, useEffect, useState, useMemo, createRef } from 'react';
import PropTypes from 'prop-types';

import { Focusable } from 'components/Navigation';
import Text, { SIZE_LARGE as TEXT_LARGE } from 'components/Text';

const baseClass = 'input-code';

const InputCode = ({
    className,
    navigation,
    codeLength,
    onLastDigitEntered,
    codeValue,
    setCodeValue
}) => {
    const [inputIsFocus, setInputIsFocus] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    const focusableRef = createRef();
    const input = createRef();

    const code = useMemo(() => Array(codeLength).fill(''), [codeLength]);

    const selectedIndex = useMemo(
        () => (codeValue.length < codeLength ? codeValue.length : codeLength - 1),
        [codeLength, codeValue]
    );

    const hideInput = useMemo(() => {
        return codeValue.length >= codeLength;
    }, [codeLength, codeValue]);

    const inputStyle = useMemo(() => {
        // TODO: make fake-input--selected position dynamic
        const leftFirstInput = window.innerWidth * 0.026; //px
        const inputWidth = window.innerWidth * 0.03;
        const inputMR = window.innerWidth * 0.005;
        const dashWidth = window.innerWidth * 0.02;
        const left =
            leftFirstInput +
            selectedIndex * (inputWidth + inputMR) +
            (selectedIndex + 1 > codeLength / 2 ? dashWidth + inputMR : 0);
        const cursorML = codeValue[selectedIndex] ? '0.8vw' : '0';
        return {
            left,
            cursorML
        };
    }, [codeLength, codeValue, selectedIndex]);

    const focusableOnBlurHandler = () => {
        input.current.blur();
        setIsFocus(false);
    };

    const focusableOnClickHandler = () => {
        input.current.focus();
    };

    const focusableOnEnterHandler = () => {
        inputIsFocus ? input.current.blur() : input.current.focus();
    };

    const focusableOnFocusHandler = () => {
        setIsFocus(true);
    };

    const focusableOnMouseEnterHandler = () => {
        navigation.current.forceFocus(focusableRef.current.focusableId);
        setIsFocus(true);
    };

    const inputOnFocusHandler = () => setInputIsFocus(true);

    const inputOnBlurHandler = () => setInputIsFocus(false);

    const inputOnChangeHandler = e => {
        const value = e.target.value;
        if (value.length > codeLength) return;
        setCodeValue(value);
    };

    useEffect(() => {
        if (hideInput && typeof onLastDigitEntered === 'function') {
            onLastDigitEntered();
        }
    }, [hideInput, onLastDigitEntered]);

    return (
        <Focusable
            className={`${baseClass} ${className}`}
            onBlur={focusableOnBlurHandler}
            onClick={focusableOnClickHandler}
            onEnterDown={focusableOnEnterHandler}
            onFocus={focusableOnFocusHandler}
            onMouseEnter={focusableOnMouseEnterHandler}
            navDefault
            ref={focusableRef}
        >
            <input
                value={codeValue}
                ref={input}
                onBlur={inputOnBlurHandler}
                onChange={inputOnChangeHandler}
                onFocus={inputOnFocusHandler}
                style={{ opacity: '0' }}
                type="number"
            />
            <div
                className={
                    'fake-input fake-input--selected' + (isFocus ? ' fake-input--focus' : '')
                }
                style={{
                    left: `${inputStyle.left}px`
                }}
            >
                {inputIsFocus && (
                    <span
                        className="fake-input__cursor"
                        style={{ marginLeft: inputStyle.cursorML }}
                    />
                )}
            </div>
            {code.map((v, index) => {
                return (
                    <Fragment key={index}>
                        <div className="fake-input">{codeValue[index]}</div>
                        {index + 1 === code.length / 2 && <Text size={TEXT_LARGE}>–</Text>}
                    </Fragment>
                );
            })}
        </Focusable>
    );
};

InputCode.propTypes = {
    className: PropTypes.string,
    codeLength: PropTypes.number,
    navigation: PropTypes.object,
    onLastDigitEntered: PropTypes.func
};

InputCode.defaultProps = {
    codeLength: 8
};

export default InputCode;
