import React, { Component, createRef } from 'react';

import { HorizontalList, VerticalList } from 'components/Navigation';
import Sidebar from 'components/SideBar';
import Button, { STYLE_OUTLINE as BUTTON_OUTLINE, SIZE_BIG as BUTTON_BIG } from 'components/Button';
import Text, {
    COLOR_GREY as TEXT_GREY,
    // COLOR_SUCCESS as TEXT_SUCCESS,
    SIZE_SMALL as TEXT_SMALL,
    // SIZE_LARGE as TEXT_LARGE
} from 'components/Text';
import Title, { LEVEL_1, LEVEL_3 } from 'components/Title';
import { AuthContext } from 'contexts';

class Profile extends Component {
    constructor(props) {
        super(props);

        this.state = { isLoaded: false, subscriptionEmailSent: false };
        this.firstBtnRef = createRef();
        this.logoutRef = createRef();
    }

    subscribeAction() {
        // TODO: send mail
        if (this.state.subscriptionEmailSent) {
        } else {
            this.setState({ subscriptionEmailSent: true });
        }
    }

    unsubscribe() {
        // TODO:
    }

    logout() {
        this.context.logout();
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    render() {
        const { history } = this.props;

        return (
            <HorizontalList className="profile">
                <Sidebar
                    ref={el => (this.sidebar = el)}
                    history={history}
                    navigation={this.props.navigation}
                    parent={this}
                />
                <VerticalList className="profile__content">
                    <Title level={LEVEL_1} className="profile__content__title">
                        Compte
                    </Title>
                    <Text className="profile__content__subtitle" color={TEXT_GREY}>
                        Gérer votre compte depuis https://chevaltv.aitakes.com/
                    </Text>

                    <div className="profile__content__email">
                        <Title level={LEVEL_3} className="profile__content__email__title">
                            E-mail
                        </Title>
                        <Text
                            className="profile__content__email__text"
                            color={TEXT_GREY}
                            size={TEXT_SMALL}
                        >
                            {this.context?.user?.email}
                        </Text>
                    </div>
                    <div className="profile__content__logout">
                        <Button
                            history={history}
                            navigation={this.props.navigation}
                            style={BUTTON_OUTLINE}
                            url={this.logout.bind(this)}
                            size={BUTTON_BIG}
                            ref={this.logoutRef}
                        >
                            <Text>Se déconnecter</Text>
                        </Button>
                    </div>
                    {/* TODO mentions légales & CGU ? Component Link (Focusable <a>)  */}
                </VerticalList>
            </HorizontalList>
        );
    }
}

Profile.contextType = AuthContext;

export default Profile;
