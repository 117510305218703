import React, { useState, createRef } from 'react';
import PropTypes from 'prop-types';
import { Focusable } from 'components/Navigation';

const baseClass = 'input';

const getCssClasses = isFocus => {
    let classes = baseClass;

    classes += isFocus ? ` ${baseClass}--focus` : '';

    return classes;
};

const Input = ({ className, label, name, navigation, navDefault, onChange, ...restProps }) => {
    const [inputIsFocus, setInputIsFocus] = useState(false);
    const [isFocus, setIsFocus] = useState(false);

    const focusableRef = createRef();
    const inputRef = createRef();

    const focusableOnBlurHandler = () => {
        inputRef.current.blur();
        setIsFocus(false);
    };

    const focusableOnClickHandler = () => inputRef.current.focus();

    const focusableOnEnterHandler = () => {
        inputIsFocus ? inputRef.current.blur() : inputRef.current.focus();
    };

    const focusableOnFocusHandler = () => setIsFocus(true);

    const focusableOnMouseEnterHandler = () => {
        navigation.current.forceFocus(focusableRef.current.focusableId);
    };

    const inputOnBlurHandler = () => setInputIsFocus(false);

    const inputOnChangeHandler = event => {
        if (typeof onChange === 'function') {
            onChange(event);
        }
    };

    const inputOnFocusHandler = () => setInputIsFocus(true);

    const inputOnKeyUpHandler = () => {};

    return (
        <Focusable
            className={`${className} ${baseClass}__wrapper`}
            navDefault={navDefault}
            onBlur={focusableOnBlurHandler}
            onClick={focusableOnClickHandler}
            onEnterDown={focusableOnEnterHandler}
            onFocus={focusableOnFocusHandler}
            onMouseEnter={focusableOnMouseEnterHandler}
            ref={focusableRef}
        >
            {label?.length > 0 && <label htmlFor={`inp-${name}`}>{label}</label>}
            <input
                autoComplete="off"
                className={getCssClasses(isFocus)}
                id={`inp-${name}`}
                name={name}
                onBlur={inputOnBlurHandler}
                onChange={inputOnChangeHandler}
                onFocus={inputOnFocusHandler}
                onKeyUp={inputOnKeyUpHandler}
                ref={inputRef}
                {...restProps}
            />
        </Focusable>
    );
};

Input.propTypes = {
    className: PropTypes.string,
    label: PropTypes.string,
    name: PropTypes.string,
    navDefault: PropTypes.bool,
    navigation: PropTypes.object,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    tabIndex: PropTypes.number,
    value: PropTypes.string.isRequired
};

Input.defaultProps = {
    autoFocus: false,
    navDefault: false
};

export default Input;
