import React from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import Wrapper from 'components/Wrapper';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    STYLE_OUTLINE as BTN_OUTLINE
} from 'components/Button';
import Title, { LEVEL_1 } from 'components/Title';
import Text from 'components/Text';

const SubscriptionVerify = ({ action, navigation }) => {
    const history = useHistory();

    return (
        <>
            <Wrapper className="inscription__form" position={Wrapper.POSITION_CENTERED}>
                <Title className="inscription__form__title" level={LEVEL_1}>
                    Vérifiez vos mails
                </Title>
                <Text className="inscription__form__text">
                    Un mail de vérification vous a été envoyé, veuillez compléter votre inscription
                    pour accéder à l'application. Une fois votre inscription validée, vous serez
                    invité à entrer un code à 8 chiffres pour configurer automatiquement votre TV
                </Text>
                <Button
                    className="inscription__form__button"
                    history={history}
                    navigation={navigation}
                    size={BUTTON_GIANT}
                    style={BTN_OUTLINE}
                    url={action}
                >
                    <Text>Entrer le code</Text>
                </Button>
            </Wrapper>
        </>
    );
};

SubscriptionVerify.propTypes = {
    action: PropTypes.func,
    navigation: PropTypes.object
};

SubscriptionVerify.defaultProps = {};

export default SubscriptionVerify;
