import axios from 'axios';

/**
 * Singleton class for Axios instance.
 */
class ApiService {
    constructor() {
        this.instance = axios.create({
            baseURL: 'https://api.chevaltv.aitakes.com/'
        });
    }

    static getInstance() {
        if (!this.instance) {
            this.instance = new ApiService();
        }
        return this.instance;
    }

    get = (...params) => this.instance.get(...params);
    post = (...params) => this.instance.post(...params);
    put = (...params) => this.instance.put(...params);
    // patch = (url, data) => this.instance.patch(url, data, {headers: {'Content-Type': 'application/merge-patch+json'}});
    remove = (...params) => this.instance.delete(...params);
}

export default ApiService.getInstance();
