import React, { Component } from 'react';

import Image from 'components/Image';
import Wrapper from 'components/Wrapper';
import SubscriptionEmail from './SubscriptionEmail';
import SubscriptionVerify from './SubscriptionVerify';
import SubscriptionCode from './SubscriptionCode';
import { goBack, goTo, isReturnEvent } from 'utils/NavigationUtils';
import ApiService from 'services/ApiService';

// eslint-disable-next-line
const emailRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

class Subscription extends Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            emailError: false,
            emailValid: false,
            isLoaded: false,
            step: 0
        };

        this.keyDownListener = this.keyDownListener.bind(this);
        this.register = this.register.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
        this.verifyAction = this.verifyAction.bind(this);
    }

    register() {
        const params = new URLSearchParams();
        params.append('email', this.state.email);
        ApiService.post('/user/createAccount.php', params)
            .then(res => {
                if (!res.data.ret) throw new Error(res.data);
                this.goToNextStep();
            })
            .catch(err => {
                this.setState({ emailError: true });
            });
    }

    verifyAction() {
        this.goToNextStep();
    }

    goToNextStep() {
        const { history } = this.props;
        const nextStep = this.state.step + 1;
        goTo(history, `${window.location.pathname}#${nextStep}`);

        this.setState({ step: nextStep });
    }

    keyDownListener(event) {
        const { step } = this.state;

        if (!isReturnEvent(event)) return;

        if (step > 0) {
            this.setState({ step: step - 1 });
        } else {
            goBack(this.props.history);
        }
    }

    handleInputChange(event) {
        const { value } = event.target;
        this.setState({ email: value, emailValid: emailRegex.test(value) });
    }

    findStepFromLocation() {
        const search = parseInt(window.location.hash?.slice(1));
        switch (search) {
            case 1:
            case 2:
                return search;
            default:
                return 0;
        }
    }

    componentDidMount() {
        window.addEventListener('keydown', this.keyDownListener);
        const stepToStart = this.findStepFromLocation();

        this.setState({ isLoaded: true, step: stepToStart });
    }

    componentDidUpdate() {
        const stepToStart = this.findStepFromLocation();
        if (this.state.step !== stepToStart) {
            this.setState({ step: stepToStart });
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    render() {
        const { email, step } = this.state;

        return (
            <div className="inscription">
                <Image
                    className="inscription__background"
                    url={require('assets/img/background.jpg')}
                    size={Image.SIZE_FULLSCREEN}
                />
                <Wrapper className="inscription__overlay" overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                    <Image
                        alt="Cheval TV"
                        className="inscription__logo"
                        url={require('assets/img/Logo-Cheval_BLANC.png')}
                    />
                    {step === 0 && (
                        <SubscriptionEmail
                            email={email}
                            emailChangeHandler={this.handleInputChange}
                            emailError={this.state.emailError}
                            isEmailValid={this.state.emailValid}
                            navigation={this.props.navigation}
                            register={this.register}
                        />
                    )}{' '}
                    {step === 1 && (
                        <SubscriptionVerify
                            action={this.verifyAction}
                            navigation={this.props.navigation}
                        />
                    )}{' '}
                    {step === 2 && <SubscriptionCode navigation={this.props.navigation} />}
                </Wrapper>
            </div>
        );
    }
}
export default Subscription;
