import React, { createRef, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { BigPlayButton, Player, ControlBar, LoadingSpinner, Shortcut } from 'video-react';
import axios from 'axios';
import 'video-react/styles/scss/video-react.scss';

import HLSSource from './HLSSource';
import Sidebar from 'components/SideBar';
import Title, { LEVEL_2 } from 'components/Title';
import Text, { SIZE_SMALL as TEXT_SMALL } from 'components/Text';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    STYLE_OUTLINE as BTN_OUTLINE
} from 'components/Button';
import { Focusable, HorizontalList } from 'components/Navigation';
import Loader from 'components/Loader';

const customShortcuts = [];

const defaultShortcuts = [
    // Override default player shortcuts
    {
        keyCode: 37, // LEFT
        handle: () => {}
    },
    {
        keyCode: 39, // RIGHT
        handle: () => {}
    },
    {
        keyCode: 38, // UP
        handle: () => {}
    },
    {
        keyCode: 40, // DOWN
        handle: () => {}
    },
    {
        keyCode: 75, // k
        handle: () => {}
    },
    {
        keyCode: 70, // f
        handle: () => {}
    },
    {
        keyCode: 74, // j
        handle: () => {}
    },
    {
        keyCode: 76, // l
        handle: () => {}
    },
    {
        keyCode: 36, // Home
        handle: () => {}
    },
    {
        keyCode: 35, // End
        handle: () => {}
    },
    {
        keyCode: 190, // Shift + >
        shift: true,
        handle: () => {}
    },
    {
        keyCode: 188, // Shift + <
        shift: true,
        handle: () => {}
    }
];

const playerConf = {
    fluid: false,
    videoId: 'Player1',
    preload: 'auto',
    autoPlay: true,
    width: '100%',
    height: '100%'
};

const shortcuts = defaultShortcuts.concat(customShortcuts);

const HLS_RENEWAL_DELAY = 30;
const HLS_MAX_RETRY = 3;

// TODO handle hls source errors
const CustomPlayer = ({ hostname, navigation, ...restProps }) => {
    let playerRef = createRef();
    const retryBtn = createRef();
    const playerFocusableRef = createRef();
    const timeouts = [];

    const history = useHistory();

    const [errorMessage, setErrorMessage] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [hlsToken, setHlsToken] = useState(false);
    const [retryCount, setRetryCount] = useState(0);
    const [showChevron, setShowChevron] = useState(true);

    const hlsSource = useMemo(() => {
        if (!hlsToken) return null;
        return `https://api.summview.com/v2/stream/a5yik1yww0yxnipfhtt5tirvdnre1gedy31wti1cj0sal0nbxijd.m3u8?access_token=${hlsToken}`;
    }, [hlsToken]);

    const saveHlsSession = data => localStorage.setItem('HLS_TOKEN', JSON.stringify(data));

    const getHlsTokenFromLocal = () => {
        try {
            const tokenData = JSON.parse(localStorage.getItem('HLS_TOKEN'));
            const now = new Date();

            const { token, expiration } = tokenData;
            if (
                !tokenData ||
                !token ||
                !expiration ||
                expiration <= now.getTime() / 1000 - HLS_RENEWAL_DELAY
            ) {
                localStorage.removeItem('HLS_TOKEN');
                throw new Error();
            }
            setHlsToken(token);
        } catch (e) {
            return false;
        }
        return true;
    };

    const requestHlsToken = async () => {
        const data = new URLSearchParams();
        // TODO env || db: identity_key, identity_secret
        data.append(
            'identity_key',
            encodeURI('DDxUkxOD9iyySLo6DpzDpOiIY5hJZUYwavOE07AmUr9oCCUKmOh3OWabGJeq0O2w')
        );
        data.append(
            'identity_secret',
            encodeURI('2dc017f3f8cc52d732805a79def652275fbe249cce400e7c9258a7d5425cbe58')
        );

        const tokenData = await axios
            .post('https://backend.summview.com/back/client/aitakes/getToken', data, {
                headers: {
                    Accept: '*/*',
                    'Accept-Language': 'fr-FR,fr;q=0.9,en-US;q=0.8,en;q=0.7',
                    'Content-Type': 'application/x-www-form-urlencoded; charset=utf-8'
                }
            })
            .then(res => res.data.data)
            .catch(err => false);

        return tokenData;
    };

    const getHlsSource = async () => {
        if (getHlsTokenFromLocal()) return;

        const tokenData = await requestHlsToken();
        if (!tokenData && retryCount < HLS_MAX_RETRY) {
            console.log('>>>SHOULD RETRY<<<'); //TODO: del
            launchRetryGetHls();
            return;
        } else if (retryCount >= HLS_MAX_RETRY) {
            setErrorMessage(
                'Impossible de récupérer la source vidéo. Veuillez réassayer plus tard'
            );
            return;
        }

        timeouts.forEach(timeout => clearTimeout(timeout));

        const expiration = Math.floor(new Date().getTime() / 1000 + tokenData.expires_in);

        saveHlsSession({ token: tokenData.access_token, expiration });
        setHlsToken(tokenData.access_token);
    };

    useEffect(() => {
        getHlsSource();
    }, [retryCount]);

    const launchRetryGetHls = () => {
        console.log('>launchRetryGetHls'); // TODO: del
        timeouts.push(setTimeout(() => setRetryCount(s => s + 1), 2000));
    };

    const retryGetHls = () => {
        setErrorMessage('');
        setRetryCount(2);
    };

    useEffect(() => {
        if (!hlsSource) return;
        setIsLoaded(true);
    }, [hlsSource]);

    useEffect(() => {
        playerRef.handleFocus();

        return () => {
            timeouts.forEach(timeout => clearTimeout(timeout));
            clearTimeout(chevronTimeout);
            playerRef?.handleBlur();
        };
    }, []);

    const onEnterDownSelectedMenuItem = () => {
        // TODO hide overlay and focus on player when click on HOME menu item (selected)
        // setShowOverlay(false);
        // console.log('>>>>>>', navigation);
        // navigation.current.forceFocus(playerFocusableRef?.current?.focusableId);
        // window.dispatchEvent(new KeyboardEvent('keyDown', { keyCode: 39 }));
    };

    const onFocusSidebarItemHandler = () => {
        showSidebar();
    };
    const onMouseEnterSidebarItemHandler = () => {
        showSidebar();
    };

    const showSidebar = () => {
        setShowOverlay(true);
    };

    const hideSidebar = () => {
        setShowOverlay(false);
    };

    const chevronTimeout = useMemo(() => {
        if (showOverlay) {
            setShowChevron(true);
            return null;
        }

        return setTimeout(() => {
            setShowChevron(false);
        }, 5000);
    }, [showOverlay]);

    const onFocusPlayerHandler = () => hideSidebar();

    const onMouseEnterPlayerHandler = () => hideSidebar();

    return (
        <div className="player">
            <Player ref={r => (playerRef = r)} {...playerConf}>
                <BigPlayButton position="center" />
                <LoadingSpinner />
                {isLoaded && <HLSSource isVideoChild src={hlsSource} />}
                <ControlBar
                    className="player__controls__overlay"
                    // autoHide={true}
                    // autoHideTime={3000}
                    disableDefaultControls
                />
                <Shortcut clickable={false} dblclickable={false} shortcuts={shortcuts} />
            </Player>
            <HorizontalList>
                <Sidebar
                    history={history}
                    navigation={isLoaded ? navigation : null}
                    className={
                        'player__sidebar' +
                        (!showOverlay && errorMessage?.length <= 0 ? ' sidebar--hide' : '')
                    }
                    onFocusItem={onFocusSidebarItemHandler}
                    onMouseEnterItem={onMouseEnterSidebarItemHandler}
                    showChevron={showChevron}
                    onEnterDownSelectedMenuItem={onEnterDownSelectedMenuItem}
                />
                {errorMessage.length > 0 ? (
                    <div className="player__error">
                        <Title level={LEVEL_2}>Une erreur est survenue</Title>
                        <Text size={TEXT_SMALL}>{errorMessage}</Text>
                        <Button
                            ref={retryBtn}
                            navigation={navigation}
                            size={BUTTON_GIANT}
                            url={retryGetHls}
                            history={history}
                            style={BTN_OUTLINE}
                            focusOnLoad
                        >
                            <Text>Réessayer</Text>
                        </Button>
                    </div>
                ) : (
                    <Focusable
                        className="player__focusable"
                        navDefault
                        onFocus={onFocusPlayerHandler}
                        onMouseEnter={onMouseEnterPlayerHandler}
                        ref={playerFocusableRef}
                    />
                )}
            </HorizontalList>
            {!isLoaded && errorMessage?.length <= 0 && <Loader className="player__loader" />}
        </div>
    );
};

CustomPlayer.propTypes = {};

CustomPlayer.defaultProps = {};

export default CustomPlayer;
