import React, { Component } from 'react';
import Hls from 'hls.js';

export default class HLSSource extends Component {
    constructor(props) {
        super(props);

        this.hls = new Hls({
            enableWorker: false,
            maxBufferLength: 6,
            maxBufferSize: 120,
            liveBackBufferLength: 0,
            liveDurationInfinity: true
        });
    }

    initPlayer() {
        const { src, video } = this.props;

        if (Hls.isSupported()) {
            this.hls.loadSource(src);
            this.hls.attachMedia(video);
            this.hls.on(Hls.Events.MANIFEST_PARSED, () => {
                video.play();
            });
        }
    }

    componentDidMount() {
        this.initPlayer();
    }

    componentWillUnmount() {
        // destroy hls video source
        if (this.hls) {
            this.hls.destroy();
        }
    }

    render() {
        return <source src={this.props.src} type={this.props.type || 'application/x-mpegURL'} />;
    }
}
