import React, { Component } from 'react';
import { connect } from 'react-redux';

import { VerticalList } from 'components/Navigation';
import MenuItem from './MenuItem';
import SvgIcon from './SvgIcon';
import { goTo, PATH_HOME, PATH_LOGOUT, PATH_PROFILE } from 'utils/NavigationUtils';
import { AuthContext } from 'contexts';
import { setThemeActive } from 'redux/actions/CatalogActions';

import { ReactComponent as Profile } from 'assets/icons/icon-profile.svg';
import { ReactComponent as Tv } from 'assets/icons/icon-tv.svg';
import { ReactComponent as IconChevronLeft } from 'assets/icons/icon-chevron-right.svg';

class Menu extends Component {
    /**
     * Array containing data for the sidebar items
     * @type {[{ref: string, icon: string, keyId: string, text: string, url: string}]}
     */
    static ITEMS = [
        {
            text: 'TV en direct',
            ref: 'home',
            keyId: 'home',
            icon: Tv,
            url: PATH_HOME,
            replaceUrl: true
        },
        {
            text: 'Compte',
            ref: 'account',
            keyId: 'account',
            icon: Profile,
            url: PATH_PROFILE,
            replaceUrl: true,
            indentLvl: 1
        }
    ];

    /**
     * Constructor method of the Menu component
     * @param props
     */
    constructor(props) {
        super(props);

        this.onGo = this.onGo.bind(this);
    }

    /**
     * Use only state to define Categories state theme
     * @param item
     */
    onGo(item) {
        const { themes, themeActive, onEnterDownSelectedMenuItem, history, setThemeActive } =
            this.props;

        if (item.url === PATH_LOGOUT) {
            this.context.logout();
        } else {
            setThemeActive(item.keyId);
            goTo(history, item.url, item.replaceUrl);
        }

        if (themes[themeActive] === item.keyId) {
            onEnterDownSelectedMenuItem();
        }
        // this.props.closeSidebar();
    }

    onMouseEnterMenuClose(event) {
        if (typeof this.props.onMouseEnterItem === 'function') this.props.onMouseEnterItem(event);
    }

    render() {
        const { themes, themeActive, navigation } = this.props;
        return (
            <ul className="menu">
                <VerticalList
                    className="menu__open"
                    ref={el => (this.menuVList = el)}
                    onFocus={() => this.props.openSidebar()}
                    onBlur={() => this.props.closeSidebar()}
                >
                    {Menu.ITEMS.map((item, i) => (
                        <MenuItem
                            key={i}
                            ref={el => (this[item.ref] = el)}
                            goTo={() => this.onGo(item)}
                            keyId={item.keyId}
                            icon={item.icon}
                            navigation={navigation}
                            parentSidebar={this}
                            selected={themes[themeActive] === item.keyId}
                            visible={this.props.visible}
                            separator={item.separator}
                            onFocusItem={this.props.onFocusItem}
                            onMouseEnterItem={this.props.onMouseEnterItem}
                        >
                            {item.text}
                        </MenuItem>
                    ))}
                </VerticalList>
                <div className="menu__close" onMouseEnter={this.onMouseEnterMenuClose.bind(this)}>
                    {/* {Menu.ITEMS.filter(
                        item =>
                            !item.minVersion ||
                            !window.getAndroidCapsuleVersion() ||
                            window.getAndroidCapsuleVersion() >= item.minVersion
                    ).map((item, i) => (
                        <SvgIcon
                            className={item.hideOnMinimize ? 'menu__close__icon--no-minimize' : ''}
                            key={i}
                            icon={item.icon}
                            separator={item.separator}
                            selected={themes[themeActive] === item.keyId}
                        />
                    ))} */}
                    {this.props.showChevron && (
                        <SvgIcon className="menu__chevron" icon={IconChevronLeft} />
                    )}
                </div>
            </ul>
        );
    }
}

Menu.contextType = AuthContext;

const stateToProps = state => ({
    themeActive: state.catalog.themeActive,
    themes: state.catalog.themes
});

const dispatchToProps = dispatch => ({
    setThemeActive: theme => dispatch(setThemeActive(theme))
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(Menu);
