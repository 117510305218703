const initialState = {
    categories: [],
    themes: ['home', 'account'],
    themeActive: 0
};

const CatalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_THEME': {
            const themeActive = state.themes.indexOf(action.payload);

            return {
                ...state,
                themeActive
            };
        }
        default:
            return { ...state };
    }
};

export default CatalogReducer;
