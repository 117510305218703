import React from 'react';
import { Provider } from 'react-redux';

import Router from './routers/Router';
import store from './redux/store';
import { AuthProvider, SystemProvider } from './contexts';
import './sass/main.scss';

const App = () => {
    return (
        <Provider store={store}>
            <SystemProvider>
                <AuthProvider>
                    <Router />
                </AuthProvider>
            </SystemProvider>
        </Provider>
    );
};

export default App;
