import React, { Component } from 'react';
import PropTypes from 'prop-types';
// import Loader from 'components/Loader';
import CssClassUtils from 'utils/CssClassUtils';

/**
 * This components is an image.
 *
 *
 **/
class Image extends Component {
    /**
     * The base css class name.
     **/
    static CSS_CLASS_BASE = 'image';

    /**
     * The full screen size.
     **/
    static SIZE_FULLSCREEN = 'fullscreen';

    /**
     * The big css class name.
     **/
    static CSS_CLASS_SIZE_FULLSCREEN =
        Image.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Image.SIZE_FULLSCREEN;

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false
        };

        this.onLoad = this.onLoad.bind(this);
    }

    /**
     * Return the css classes (depending on the params).
     *
     * @param string size The size of the image (fullscreen).
     *
     * @return string The css classes.
     *
     *
     **/
    getCssClasses(className, size) {
        let classes = className ? `${className} ${Image.CSS_CLASS_BASE}` : Image.CSS_CLASS_BASE;

        if (size === Image.SIZE_FULLSCREEN) {
            classes += CssClassUtils.CLASS_SEPARATOR + Image.CSS_CLASS_SIZE_FULLSCREEN;
        }

        return classes;
    }

    onLoad() {
        this.setState({ isLoaded: true });
    }

    /** Render the image. */
    render() {
        const { className, size, url, alt, onError } = this.props;

        return (
            <>
                {/* {!this.state.isLoaded && <Loader />} */}
                <img
                    className={this.getCssClasses(className, size)}
                    src={url}
                    alt={alt}
                    onLoad={this.onLoad}
                    onError={onError}
                />
            </>
        );
    }
}

Image.propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    size: PropTypes.oneOf([Image.SIZE_FULLSCREEN, '']),
    url: PropTypes.string
};

Image.defaultProps = {
    className: '',
    size: ''
};

export default Image;
