import React, { Component, createRef } from 'react';
import { VerticalList } from 'components/Navigation';

import { AuthContext } from 'contexts';

import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    STYLE_OUTLINE as BTN_OUTLINE
} from 'components/Button';
import Title, { LEVEL_1 } from 'components/Title';
import Text, { COLOR_ERROR, ALIGN_CENTER } from 'components/Text';
import Image from 'components/Image';
import { goBack, goTo, isReturnEvent, PATH_HOME } from 'utils/NavigationUtils';

class Login extends Component {
    /**
     * The navigation object.
     */
    navigation = null;

    /**
     * The error message for empty email.
     */
    static MESSAGE_ERROR_EMAIL = "L'adresse Email doit être renseignée";

    /**
     * The error message for empty password.
     */
    static MESSAGE_ERROR_PASSWORD = 'Le mot de passe doit être renseigné';

    /**
     * The error message for bad password / id.
     */
    static MESSAGE_ERROR_VALUES = 'E-mail et/ou mot de passe incorrect';

    /**
     * Set the isLoaded state to false.
     * @param Object props
     */
    constructor(props) {
        super(props);

        this.state = {
            messageError: '',
            isLoaded: false,
            email: '',
            emailError: false,
            password: '',
            passwordError: false,
            step: 1,
            errorMessage: ''
        };

        this.nextStep = this.nextStep.bind(this);
        this.login = this.login.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);

        this.next1 = createRef();
        this.next2 = createRef();
    }

    keyDownListener(evnt) {
        if (isReturnEvent(evnt)) {
            goBack(this.props.history);
        }
    }

    isEmailValid() {
        return this.state.email.trim().length > 0;
    }

    isPasswordValid() {
        if (this.state.loginError) this.setState({ loginError: false });
        return this.state.password.trim().length > 0;
    }

    handleInputChange(event) {
        if (this.state.messageError) this.setState({ messageError: '' });

        const { value, name } = event.target;
        switch (name) {
            case 'password':
                this.setState({ password: value });
                break;
            case 'email':
                this.setState({ email: value });
                break;
            default:
                break;
        }
    }

    nextStep() {
        if (!this.isEmailValid()) {
            this.setState({ emailError: true });
            return;
        }

        this.setState({
            emailError: false,
            step: this.state.step + 1
        });
    }

    login() {
        if (!this.isPasswordValid()) {
            this.setState({ passwordError: true });
            return;
        }

        const { email, password, passwordError } = this.state;

        if (passwordError) this.setState({ passwordError: false });

        this.context
            .login(email, password)
            .then(res => {
                if (!res.data.ret) {
                    switch (res.data.data) {
                        case 'Not subscribed':
                            this.setState({ errorMessage: "Vous n'êtes pas abonné" });
                            break;
                        case 'Authentication error':
                            this.setState({ errorMessage: 'Email ou mot de passe incorrect' });
                            break;
                        default:
                    }
                    return;
                }
                goTo(this.props.history, PATH_HOME, true);
            })
            .catch(err => {
                // this.setState({ loginError: true });
            });
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
        window.addEventListener('keydown', this.keyDownListener);
    }

    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    render() {
        return (
            <VerticalList className="login">
                <Image url={require('assets/img/background.jpg')} size={Image.SIZE_FULLSCREEN} />
                <Wrapper className="login__overlay" overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                    <Image
                        alt="Cheval TV"
                        className="login__logo"
                        url={require('assets/img/Logo-Cheval_BLANC.png')}
                    />
                    {this.state.step === 1 && (
                        <Wrapper className="login__form" position={Wrapper.POSITION_CENTERED}>
                            <Title className="login__form__title" level={LEVEL_1}>
                                Connectez vous avec votre adresse e-mail
                            </Title>
                            <Input
                                className="login__form__input"
                                type="text"
                                placeholder="E-mail"
                                name="email"
                                size={Input.SIZE_BIG}
                                navigation={this.props.navigation}
                                tabIndex={1}
                                autoFocus={true}
                                onChange={this.handleInputChange}
                                value={this.state.email}
                                withLabel={false}
                            />
                            <Button
                                ref={this.next1}
                                className="login__form__button"
                                history={this.props.history}
                                navigation={this.props.navigation}
                                size={BUTTON_GIANT}
                                style={BTN_OUTLINE}
                                url={this.nextStep}
                            >
                                <Text>Continuer</Text>
                            </Button>

                            {this.state.emailError && (
                                <Text state={COLOR_ERROR} align={ALIGN_CENTER}>
                                    {Login.MESSAGE_ERROR_EMAIL}
                                </Text>
                            )}
                        </Wrapper>
                    )}
                    {this.state.step === 2 && (
                        <Wrapper className="login__form" position={Wrapper.POSITION_CENTERED}>
                            <Title className="login__form__title" level={LEVEL_1}>
                                Entrez votre mot de passe
                            </Title>
                            <Input
                                className="login__form__input"
                                type="password"
                                placeholder="Mot de passe"
                                name="password"
                                size={Input.SIZE_BIG}
                                navigation={this.props.navigation}
                                tabIndex={2}
                                onChange={this.handleInputChange}
                                value={this.state.password}
                                withLabel={false}
                            />
                            <Button
                                ref={this.next2}
                                className="login__form__button"
                                history={this.props.history}
                                navigation={this.props.navigation}
                                size={BUTTON_GIANT}
                                style={BTN_OUTLINE}
                                url={this.login}
                            >
                                <Text>Se connecter</Text>
                            </Button>

                            {this.state.passwordError && (
                                <Text state={COLOR_ERROR} align={ALIGN_CENTER}>
                                    {Login.MESSAGE_ERROR_PASSWORD}
                                </Text>
                            )}
                        </Wrapper>
                    )}
                    {this.state.errorMessage.length > 0 && (
                        <Text state={COLOR_ERROR} align={ALIGN_CENTER}>
                            {this.state.errorMessage}
                        </Text>
                    )}
                </Wrapper>
            </VerticalList>
        );
    }
}

Login.contextType = AuthContext;

export default Login;
